import {clientHelpers} from "@/mixins/clientHelpers";
import {personHelpers} from "@/mixins/personHelpers";
import {dateFormat} from "@/mixins/dateFormat";

export const helpers = {
    mixins: [clientHelpers, personHelpers, dateFormat],
    computed: {
        title () {
            if (this.consent.will) return 'Will Role Notification'
            if (this.consent.lpa) return 'Attorney Notification'
            return 'You have been named in a Product'
        },
        subTitle () {
            return `${this.clientNameList({
            clients: this.consent.person.clients,
            nameEnding: "'s"
            })} ${this.product}`
        },
        product() {
            if (this.consent.will) return 'Will'
            if (this.consent.lpa) return 'LPA'
            return 'product'
        },
        clientFullNames() {
            if (this.consent?.person?.clients) {
                return this.clientNameList({clients: this.consent.person.clients})
            }
            return ''
        },
        clientFirstNames() {
            if (this.consent?.person?.clients) {
                return this.clientNameList({
                    clients: this.consent.person.clients,
                    firstName: true,
                    nameEnding: "'s"
                })
            }
            return ''
        },
        personName() {
            return this.fullName(this.consent.person)
        },
        productAndDate() {
            return `${this.product} ${this.formatDate(this.consent.created_date)}`
        }
    }
}
