<template>
  <div class="main-content-wrap d-flex flex-column">
    <ProductRoleInformation
        v-if="data && data.consent"
        :consent="data.consent"
    />
  </div>
</template>

<script>
import {httpQuestionnaire} from "@/services";
import {toast} from '@/mixins/toast'

import ProductRoleInformation from "@/views/pages/ProductRole/ProductRoleInformation";

export default {
  name: 'ProductRole',
  mounted() {
    if (this.$route.params.code) {
      // clear tokens if logged in
      this.$store.commit('removeTokens');
      // client one time access via code
      this.$store.dispatch('setClientAuthorizationHeader', this.$route.params.code)
      this.$store.commit('accessCode', this.$route.params.code)
      this.fetch()
    }
  },
  created() {
    this.pageLoadToastMessage()
  },
  mixins: [toast],
  components: {
    ProductRoleInformation,
  },
  data() {
    return {
      data: null
    }
  },
  methods: {
    fetch() {
      httpQuestionnaire.get('product_role',
          {params: {code: this.$route.params.code}}).then(response => {
        this.data = response.data
      }).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>
