<template>
  <div class="card-feedback-wrapper">
    <b-card class="card-feedback">
      <blockquote class="mb-0">
        <p class="text-small text-gray-600 mb-0" v-text="text"></p>
      </blockquote>
    </b-card>
    <div class="mt-20 d-flex pl-3">
      <img src="@/assets/images/woman-profile.svg"
           alt="person"
           class="thumbnail"/>
      <div class="d-flex flex-column">
        <h6 class="mb-0 ml-2" v-text="name" />
        <b-form-rating
            class="b-rating-inline bg-transparent"
            :value="5"
            variant="warning"
            size="sm"
            show-value
            show-value-max
            readonly
            inline
            no-border />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackCard',
  props: {
    text: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
}
</script>