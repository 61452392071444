<template>
  <b-col md="4" offset-md="1">
    <div class="section-title">
      <h4 class="pl-0 pr-3 pt-3 pb-3">
        <span class="pl-0">About Us</span>
      </h4>
    </div>
    <b-row>
      <b-col cols="12">
        <p style="margin-top: -30px">
          Dunham McCarthy is leading provider of legal and financial services. We specialise in helping people
          protect their families by putting Wills, Trusts and Life Insurance in place.
        </p>
      </b-col>
      <b-col md="12">
        <div class="d-flex" style="column-gap: 30px">
          <p>
            <a class="d-flex justify-items-center" href="#">
              <i class="i-Telephone pr-2  text-secondary text-20"/>
              01785 336222
            </a>
          </p>

          <p>
            <a class="d-flex justify-items-center" href="https://dm-legal.co.uk" target="_blank">
              <i class="i-Internet pr-2 text-secondary text-20"/>
              DM-Legal.co.uk
            </a>
          </p>
        </div>

        <p>
          <a class="d-flex justify-items-center" href="mailto:enquiries@dunham-mccarthy.co.uk">
            <i class="i-Mail pr-2 text-secondary text-20"/>
            Enquiries@Dunham-Mccarthy.co.uk
          </a>
        </p>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: 'AboutUsSection'
}
</script>