<template>
  <b-card @click.stop="$bvModal.show(modalId)" class="hover-down dash-btn white bg-secondary">
    <img alt="shield"
         class="btn-background-img"
         src="@/assets/images/start-5.png">
    <div class="face">
      <div class="face-top">
        <h3>Free<br>Mortgage<br>Review</h3>
      </div>
      <div class="face-bottom">
        <i class="i-Tag"/>
        <span>Free Advice</span>
      </div>
    </div>
    <div class="back">
      <p>Organise or review life insurance, critical illness cover or income protection. Service provided by Dunham
        McCarthy Financial Services.
      </p>
      <h3>Click to Book</h3>
    </div>
    <b-modal :id="modalId" title="Book Mortgage Review" size="xl" content-class="border-0" title-class="text-white" header-class="bg-primary" body-class="pr-30 pl-30" ok-only ok-title="Cancel"
             hide-footer>
      <b-row>
        <b-col md="6">
          <ul class="list-square pt-20">
            <li>
              <h6>Find the best mortgage provider</h6>
              <p>We search the major high street lenders like HSBC, Nationwide and Santander and many more, as well as smaller specialist lenders, all to get you the best deal.</p>
            </li>
            <li>
              <h6>Free advice</h6>
              <p>We don't charge to review your mortgage or research your options. If you decide to go ahead we charge only £245 to organise a remortgage.</p>
            </li>
            <li>
              <h6>Access exclusive mortgage products</h6>
              <p>In addition to thousands of mainstream mortgage products, we have access to many exclusive products that aren't available elsewhere.</p>
            </li>
            <li>
              <h6>Co-ordinate the remortgage process</h6>
              <p>Once we have found you the right product, we provide advice and guidance throughout the whole process from start to finish.</p>
            </li>
          </ul>
        </b-col>
        <b-col md="5" offset-md="1">
          <BookingForm
              :transaction="null"
              :event="event"
              :clients="clients"
              source="Role Referral"
              appointment-type="Mortgage Consultation"
              host-role="Mortgage Advisor"
              :show-new-client="true"
              :show-confirmation-panel="true"
              :access-code="$store.getters.accessCode"
              :notes="notes"
              :location="location"
              @confirmed="$emit('confirmed', $event)"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import BookingForm from "@/components/common/appointment/calender/BookingForm";

export default {
  name: 'MortgageReviewCard',
  components: {BookingForm},
  props: {
    clients: {
      type: Array,
      required: false
    },
    notes: {
      type: String,
      required: false
    },
    location: {
      type: String,
      required: false
    },
    event: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      modalId: 'mortgage-card-review-modal',
    }
  }
}
</script>