<template>
  <div class="border-wide-lef border-gray-200 pl-32">
    <template v-for="(role, index) in consent.roles_in_product">

      <div :key="`role${index}${role}`">
        <div
            :class="'completed'"
            class="d-flex align-items-center page-num-container pt-80"
        >
          <div class="page-num"></div>
          <h4>{{ role }}</h4>
        </div>

        <b-row>
          <b-col>
            <div v-if="role === 'Trustee'" class="mb-lg-32">
              <p> Once Executors have completed the general administration of an estate they can then normally
                distribute assets, however where assets are instead left to a minor a child or grandchild for example,
                somebody will need to look after the assets until the beneficiary comes of age; this the role of
                'Trustees'. Trustees have a number of duties and responsibilities, they will:</p>
              <ul class="list-unstyled mb-0">
                <li class="position-relative pl-30 success chevron-before">Safeguard the Trust property;</li>
                <li class="position-relative pl-30 success chevron-before">Invest the Trust property;</li>
                <li class="position-relative pl-30 success chevron-before">Act with honesty and integrity;</li>
                <li class="position-relative pl-30 success chevron-before">Consult with beneficiaries;</li>
                <li class="position-relative pl-30 success chevron-before">Keep proper records and accounts.</li>
              </ul>
            </div>
            <div v-else-if="role === 'Guardian'" class="mb-lg-32">
              <p>The role is essentially the same as that of a parent. A guardian has the legal authority to make
                decisions on behalf of a child with regard to their upbringing, schooling and anything else a parent
                would otherwise deal with. As guardian you will need to:</p>
              <ul class="list-unstyled mb-0">
                <li class="position-relative pl-30 success chevron-before">Provide proper care, maintenance,
                  education, and support;</li>
                <li class="position-relative pl-30 success chevron-before">Supply food, clothing, shelter, and
                  necessaries;</li>
                <li class="position-relative pl-30 success chevron-before">Authorise medical, surgical, dental and psychological care;</li>
                <li class="position-relative pl-30 success chevron-before">Making sure the child is properly
                  educated.</li>
              </ul>
            </div>
            <div v-else-if="role === 'Executor'" class="mb-lg-32">
              <p>An executor is a person named in a Will to carry out the wishes of the deceased person. Amongst other
                things their role is to locate, manage and disburse the assets of the estate. Executors have a number of
                duties and responsibilities, they will have to: </p>
              <ul class="list-unstyled mb-0">
                <li class="position-relative pl-30 success chevron-before">Inform institutions where the deceased
                  held assets;</li>
                <li class="position-relative pl-30 success chevron-before">Obtain valuations of assets held by the
                  deceased; </li>
                <li class="position-relative pl-30 success chevron-before">Complete the inheritance tax returns and
                  pay any taxes due; </li>
                <li class="position-relative pl-30 success chevron-before">Collect the assets of the deceased;</li>
                <li class="position-relative pl-30 success chevron-before">Pay the debts and liabilities of the
                  estate;</li>
                <li class="position-relative pl-30 success chevron-before">Transfer assets to the beneficiaries, pay
                  legacies and distribute the estate.</li>
              </ul>
            </div>

            <!--            <p v-else-if="role === 'Pet Guardian'" class="mb-lg-32">-->
            <!--              You have been named as an pet guardian in an Will-->
            <!--            </p>-->
          </b-col
          >
        </b-row>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'WillRoles',
  props: {
    consent: {
      type: Object,
      required: true
    }
  }
}
</script>