<template>
  <div class="border-wide-left border-gray-200 pl-32">
    <template v-for="(role, index) in consent.roles_in_product">

      <div :key="`role${index}${role}`">
        <div
            :class="'completed'"
            class="d-flex align-items-center page-num-container pt-80"
        >
          <div class="page-num"></div>
          <h4>{{ role }}</h4>
        </div>

        <b-row>
          <b-col>
            <div v-if="role === 'Attorney'" class="mb-lg-32">
              <p>An attorney has the legal authority to make decisions on behalf of the Donor.</p>
              <ul class="list-unstyled mb-0">
                <li class="position-relative pl-30 success chevron-before">Safeguard the Trust property;</li>
                <li class="position-relative pl-30 success chevron-before">Invest the Trust property;</li>
                <li class="position-relative pl-30 success chevron-before">Act with honesty and integrity;</li>
                <li class="position-relative pl-30 success chevron-before">Consult with beneficiaries;</li>
                <li class="position-relative pl-30 success chevron-before">Keep proper records and accounts.</li>
              </ul>
              <p>The attorney is responsible for acting in the best interests of the
                principal and must follow the principles set out in the Mental Capacity Act 2005 when making
                decisions on their behalf.
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'WillRoles',
  props: {
    consent: {
      type: Object,
      required: true
    }
  }
}
</script>