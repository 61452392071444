<template>
  <b-col md="7">
    <div class="section-title">
      <h4 class="pl-0 pr-3 pt-3 pb-3">
        <span class="pl-0">Introduction</span>
      </h4>
    </div>
    <p style="margin-top: -30px">
      You have received this link because we have prepared a {{ product }} for {{ clientFullNames }} and you have
      been given a number of responsibilities. You do not need to do anything, this web page simply provides
      details of the appointment and general information about your role.
    </p>
    <p>
      If you have any questions or if you are interested in putting your {{ product }} in place, please get in touch.
    </p>
  </b-col>
</template>

<script>
import { helpers} from "@/views/pages/ProductRole/sections/helpers";

export default {
  name: "Introduction",
  mixins: [helpers],
  props: {
    consent: {
      type: Object,
      required: true
    }
  }
};
</script>
