<template>
  <div v-if="consent">
    <Header
        :title="title"
        :sub-title="subTitle"
        :banner-image-source="require('@/assets/images/trans-banner.png')"
        bar-info1-icon="i-Administrator"
        bar-info2-icon="i-Folder-With-Document"
        :barInfo1Text="personName"
        :bar-info2-text="productAndDate"
    />

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction :consent="consent"/>
          <AboutUsSection/>
        </b-row>
      </b-container>
    </div>

    <div class="border-bottom-gray-200 pt-40 pb-40 bg-white">
      <b-container>
        <RolesOverviewSection :consent="consent"/>
      </b-container>
    </div>

    <div class="border-bottom-gray-200">
      <b-container>
        <b-row>
          <b-col md="7">
            <WillRoles
                v-if="consent.will"
                :consent="consent"
            />

            <LpaRoles
                v-else-if="consent.lpa"
                :consent="consent"
            />

            <PolicyRoles
                v-else-if="consent.policy"
                :consent="consent"
            />
          </b-col>

          <b-col lg="4" offset-md="1" style="margin-top: 40px">
            <b-row>
              <b-col cols="12">
                <InsuranceReviewCard
                    :event="consent.event"
                    :clients="clients"
                    :bookingNotes="null"
                    :notes="insuranceNotes"
                    location="Telephone"
                    @confirmed="eventConfirmed"
                />
              </b-col>

              <b-col cols="12">
                <MortgageReviewCard
                    :event="consent.event"
                    :clients="clients"
                    :bookingNotes="null"
                    :notes="mortgageNotes"
                    location="Telephone"
                    @confirmed="eventConfirmed"
                />
              </b-col>

              <b-col cols="12">
                <DiscountedWillWritingCard
                    :event="consent.event"
                    :clients="clients"
                    :notes="estatePlanningNotes"
                    location="Telephone"
                    @confirmed="eventConfirmed"
                />
              </b-col>
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </div>

    <OfferBookingSection>
      <template #bookingForm>
        <BookingForm
            ref="bookingForm"
            :transaction="null"
            appointment-type="General Consultation"
            host-role="Estate Planner"
            source="Role Referral"
            :show-new-client="true"
            :clients="clients"
            :event="consent.event"
            :show-confirmation-panel="true"
            :access-code="$store.getters.accessCode"
            :notes="estatePlanningNotes"
            @confirmed="eventConfirmed"
        />
      </template>
    </OfferBookingSection>

    <Reviews/>
  </div>
</template>
<script>
import {helpers} from "@/views/pages/ProductRole/sections/helpers";
import { textHelpers } from "@/mixins/textHelpers";

import Introduction from "@/views/pages/ProductRole/sections/Introduction";
import Header from "@/views/pages/layout/Header";
import WillRoles from "@/views/pages/ProductRole/sections/WillRoles";
import LpaRoles from "@/views/pages/ProductRole/sections/LpaRoles";
import Reviews from "@/components/common/other/Reviews";
import AboutUsSection from "@/views/pages/layout/sections/AboutUsSection";
import RolesOverviewSection from "@/views/pages/ProductRole/sections/RolesOverviewSection";
import DiscountedWillWritingCard from "@/views/pages/layout/cards/DiscountedWillWritingCard";
import MortgageReviewCard from "@/views/pages/layout/cards/MortgageReviewCard";
import InsuranceReviewCard from "@/views/pages/layout/cards/InsuranceReviewCard";
import OfferBookingSection from "@/views/pages/layout/sections/OfferBookingSection";
import BookingForm from "@/components/common/appointment/calender/BookingForm";
import PolicyRoles from "@/views/pages/ProductRole/sections/PolicyRoles.vue";

export default {
  name: 'ProductRoleInformation',
  components: {
    PolicyRoles,
    BookingForm,
    OfferBookingSection,
    InsuranceReviewCard,
    MortgageReviewCard,
    DiscountedWillWritingCard,
    RolesOverviewSection,
    AboutUsSection, Reviews, LpaRoles, WillRoles, Header, Introduction
  },
  mixins: [helpers, textHelpers],
  props: {
    consent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      bookingEvent: null
    }
  },
  computed: {
    clients () {
      // needed to set clients in multiple booking modals
      // check in consent person and event clients
      if (this.consent.person.converted_to_client) {
        return [this.consent.person.converted_to_client]
      }
      else if (this.consent?.event?.clients?.length) {
        return this.consent.event.clients
      }
      return null
    },
    insuranceNotes() {
      if (this.consent) {
        let roles = this.listWords({words: this.consent.roles_in_product})
        return `${this.clientFullNames} has made a ${this.product} that i was named in as a ${roles} and I would like some protection advice`
      } return null
    },
    mortgageNotes() {
      if (this.consent) {
        let roles = this.listWords({words: this.consent.roles_in_product})
        return `${this.clientFullNames} has made a ${this.product} that i was named in as a ${roles} and I would like some mortgage advice`

      } return null
    },
    estatePlanningNotes() {
      if (this.consent) {
        let roles = this.listWords({words: this.consent.roles_in_product})
        return `${this.clientFullNames} has made a ${this.product} that i was named in as a ${roles} and I would like to take advantage of your estate planning discount`

      } return null
    }
  },
  methods: {
    eventConfirmed(data) {
      console.log('event confirmed', data)
      this.consent.event = data
    }
  }
}
</script>
