<template>
  <div class="border-wide-left border-gray-200 pl-32">
    <template v-for="(role, index) in consent.roles_in_product">

      <div :key="`role${index}${role}`">
        <div
            :class="'completed'"
            class="d-flex align-items-center page-num-container pt-80"
        >
          <div class="page-num"></div>
          <h4>{{ role }}</h4>
        </div>

        <b-row>
          <b-col>
            <div v-if="role === 'Policy Trustee'" class="mb-lg-32">
              <p>
                A trustee in an insurance policy is a person or entity that is responsible for managing the
                policyholder's assets and ensuring that the policyholder's wishes are carried out. The trustee
                is responsible for making sure that the policyholder's beneficiaries receive the benefits of the
                policy in accordance with the policyholder's wishes.
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PolicyRoles',
  props: {
    consent: {
      type: Object,
      required: true
    }
  }
}
</script>