<template>
  <div class="pb-150 pt-150 bg-white position-relative">
    <b-container>
      <div class="ribbon-offer-down-bg">
        <div style="line-height: 1.3" class="offer-text text-20">FRIENDS / FAMILY<br>OFFER</div>
      </div>
      <b-row style="flex-direction: row-reverse; justify-content: flex-end">
        <b-col md="5" offset-md="1" class="pt-40">
          <h1>
            Have you made your Will?
          </h1>
          <div class="section-title white">
            <h4 class="pl-0 pr-3 pt-3 pb-3">
              <span class="pl-0">50% off Friends / family offer</span>
            </h4>
          </div>

          <p>
            We have helped over 50,000 people protect their families by making a will. If you are interested in making
            yours we can help.
          </p>
          <p>
            Our fee for a single will is usually £179 (£249 for two mirror wills), for friends and
            family we offer a 50% discount meaning you can have your Will professionally drafted for only £89.5.
          </p>
          <p>
            To book an appointment to organise your Will(s) simply select a date on the calendar and enter your
            details.
          </p>
          <img class="mt-30" style="max-width:60%" src="@/assets/images/Logo-dark.png" alt="dunham mccarthy logo"/>
        </b-col>
        <b-col md="6">
          <slot name="bookingForm">
            <BookingForm
                ref="bookingForm"
                :transaction="null"
                appointment-type="Estate Planning Consultation"
                host-role="Estate Planner"
                :event="event"
                :show-new-client="true"
                :show-confirmation-panel="true"
                @confirmed="$emit('confirmed')"
            />
          </slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BookingForm from "@/components/common/appointment/calender/BookingForm";
export default {
  name: 'OfferBookingSection',
  components: {BookingForm},
  props: {
    event: {
      type: Object,
      required: false
    }
  }
}
</script>