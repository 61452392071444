<template>
  <b-row>
    <b-col md="7">
      <div class="border-extra-wide-left border-secondary pl-40"><h4 class="mb-30">Your
        Role{{ consent.roles_in_product.length > 1 ? 's' : '' }}</h4>

        <ul class="list-unstyled mb-0">

          <li class="position-relative pl-30 success chevron-before"
              v-for="(role, index) in consent.roles_in_product" :key="`role${index}`">
            You are appointed as {{ clientFirstNames }} {{ role }}
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {helpers} from "@/views/pages/ProductRole/sections/helpers";

export default {
  name: 'RolesOverviewSection',
  mixins: [helpers],
  props: {
    consent: {
      type: Object,
      required: true
    }
  }
}
</script>