<template>
  <div>
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img
            alt="shield"
            class="trans-background"
            :src="bannerImageSource"
        />
        <div class="container">
          <h1 class="text-white">{{ title }}</h1>
          <h3 class="arrow-before text-white pl-30">{{ subTitle }}<br/>
          </h3>
        </div>
      </div>
    </div>

    <div v-if="barInfo1Text && barInfo2Text"
         class="hero-sub-bar-two-header border-bottom-gray-200 bg-white w-100 d-flex flex-column justify-content-center">
      <b-container>
        <div class="hero-sub-bar-two-heading d-flex text-16 font-weight-400">

          <div v-if="barInfo1Text && barInfo1Icon" class="d-flex hero-sub-bar-two-heading-stat pr-30">
            <i class="pr-12 text-22 text-mute" :class="barInfo1Icon"></i>
            {{ barInfo1Text }}
          </div>

          <div v-if="barInfo2Text && barInfo2Icon" class="d-flex hero-sub-bar-two-heading-stat">
            <i class="pr-16 text-22 text-mute" :class="barInfo2Icon">
            </i>{{ barInfo2Text }}
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false
    },
    barInfo1Text: {
      type: String,
      required: false
    },
    barInfo1Icon: {
      type: String,
      required: false
    },
    barInfo2Text: {
      type: String,
      required: false
    },
    barInfo2Icon: {
      type: String,
      required: false
    },
    bannerImageSource: {
      type: String,
      required: true
    }
  }
}
</script>