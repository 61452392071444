<template>
  <div style="height: 150px; overflow-x: clip">
    <div class="slider">
      <div class="slider__track">
        <div class="slider__slide">
          <FeedbackCard
              text="Very informative, professional and helpful company no pressures to take out unnecessary policies. Video calling worked well and responses to questions either on call or email were swift. Definitely recommend them to others."
              name="Kevin Taylor"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Great service from these guys, they did a wonderful job sorting our wills. The whole process was really easy and the support was brilliant. Would highly recommend."
              name="Rebecca England"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Friendly and polite representative. Sarah gave impartial advice without pushy salestalk. She made everything as clear and easy as possible to understand and readily answered any questions we raised."
              name="Chris Spellman"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Great service! Jon was brilliant, came out of his way to meet with us and explained everything. Couldn't be happier - weight off our minds too!"
              name="Mal McCallion"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Fab, easy experience, Tracey was really friendly and made the whole experience easy, explained everything really well and advised us on what was best. Would 100% recommend to anyone."
              name="Claire Blair"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Brilliant service well delivered they had no problems in explaining things to us clearly and learning about the process! Well worth the time spent Well done Dexter!"
              name="Ursula Thompson"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Extremely experienced staff every step of the way, helpful and informative details given when needed. Cannot thank Tracey Clatke enough for helping my family and I throughout the entire process."
              name="Susie Abbotts"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="An excellent service with excellent staff thanks Nadeem Khan for everything you have done for us!! I would definitely recommend Dunham McCarthy."
              name="Tarun Sharma"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Absolutely excellent, professional service from start to finish.  Everything explained in easy to understand terms.  Would thoroughly recommend."
              name="Debbie Turner"
          />
        </div>
        <div class="slider__slide">
          <FeedbackCard
              text="Very professional service. Everything is explained clearly and our representative was excellent. Would recommend them without hesitation."
              name="Vanessa Dison"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackCard from "@/views/introducer/dashboard/FeedbackCard";

export default {
  name: "Reviews",
  components: {
    FeedbackCard
  }
}
</script>
