<template>
  <b-card @click.stop="$bvModal.show(modalId)" class="hover-down dash-btn white bg-primary">
    <img alt="shield"
         class="btn-background-img"
         src="@/assets/images/start-4.png">
    <div class="face">
      <div class="face-top">
        <h3>Free<br>Insurance<br>Review</h3>
      </div>
      <div class="face-bottom">
        <i class="i-Tag"/>
        <span>Free Advice</span>
      </div>
    </div>
    <div class="back">
      <p>Organise or review life insurance, critical illness cover or income protection. Service provided by Dunham
        McCarthy Financial Services.
      </p>
      <h3>Click to Book</h3>
    </div>
    <b-modal :id="modalId" title="Book Insurance Review" size="xl" content-class="border-0" title-class="text-white" header-class="bg-primary" body-class="pr-30 pl-30" ok-only ok-title="Cancel"
             hide-footer>
      <b-row>
        <b-col md="6">
          <ul class="list-square pt-20">
            <li>
            <h6>Compare providers</h6>
            <p>Our expert advisors can help you find the best policy from many providers such as Legal & General,
            Aviva, Vitality and Scottish Widows.</p>
            </li>
            <li>
              <h6>No fee to advise & arrange</h6>
              <p>We don't charge to discuss your requirements and there are no fees for us to research and explain your
            options, we don't even charge to arrange the cover.</p>
            </li>
            <li>
              <h6>Health based recommendation</h6>
              <p>When we make a recommendation we take into account your health, family history and lifestyle, this means
            that when we give you a price, it is less likely to be adjusted following the underwriting process.</p>
            </li>
            <li>
              <h6>Policies written into trust</h6>
              <p>To minimise delays and reduce Inheritance Taxes, we can write you policy into a discretionary trust, again
            no charge.</p>
            </li>
          </ul>
        </b-col>
        <b-col md="5" offset-md="1">
          <BookingForm
              :transaction="null"
              :event="event"
              :clients="clients"
              source="Role Referral"
              appointment-type="Insurance Consultation"
              host-role="Protection Advisor"
              :show-new-client="true"
              :show-confirmation-panel="true"
              :access-code="$store.getters.accessCode"
              :notes="notes"
              :location="location"
              @confirmed="$emit('confirmed', $event)"
          />
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import BookingForm from "@/components/common/appointment/calender/BookingForm";

export default {
  name: 'InsuranceReviewCard',
  components: {BookingForm},
  props: {
    clients: {
      type: Array,
      required: false
    },
    notes: {
      type: String,
      required: false
    },
    location: {
      type: String,
      required: false
    },
    event: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      modalId: 'insurance-card-review-modal',
    }
  }
}
</script>